@use "../forgotPassword.styles.scss" as *;

.newPassword_container {
    @include container();
    width: 600px;
}

.company-logo {
  width: 200px;
}

.reset-password-btn {
  position: absolute;
  bottom: 0;
  right: 20px;
}

.ResetPasswordBackground {

    // clip-path: polygon(61% 0, 100% 0%, 100% 100%, 0% 100%);
    background-size: cover;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 800px;
    height: 550px;
    overflow: hidden;
    border-radius: 15px;
    margin-top: 20px;
  
    .afterReset {
      flex-direction: column;
      @include container();
  
      .afterReset_btnContainer {
        display: flex;
        flex-direction: row;
        gap: 20px;
        padding: 20px 0;
      }
  
      .afterReset_helpBtn {
        background-color: transparent;
        border: 2px solid black;
        width: 100%;
        border-radius: 50px;
  
        span {
          margin: 0;
          padding: 0;
        }
      }
    }
  
    // .forgotPasswordBtn {
    //   background-color: var(--secondary-color);
    //   width: 100%;
    //   border-radius: 50px;
  
    //   span {
    //     margin: 0;
    //     padding: 0;
    //   }
    // }
  
    .forgotPasswordContainer {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      @include container();
  
      .forgotPasswordContainer_columnLeft {
        width: 60%;
      }
      .forgotPasswordContainer_columnRight {
        width: 40%;
      }
  
      .inputContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px 0;
      }
  
      .clickHere {
        color: blue;
        transition: all 0.2 ease;
        cursor: pointer;
  
        &:hover {
          text-decoration: underline;
        }
      }
    }
}