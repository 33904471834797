.edit-devices-header {
    padding: 1vh 20px 1vh 100px;
    background-color: #dee7f2;
    margin-bottom: 0px;
    display: grid;
    grid-template-columns: 0.9fr 0.10fr 0.10fr;
    width: 100%;
    gap: 20px;
    align-items: center;
}

.edit-devices-header-small {
    background-color: #dee7f2;
    display: grid;
    grid-template-columns: 0.9fr 0.15fr 0.15fr;
    width: 100%;
    gap: 20px;
    padding: 20px 0px 20px 20px;
    align-items: center;
    margin-top: -30px;
}

.edit-device-main {
    padding: 1vh 100px;
}

.device-item-btn {
    cursor: pointer;
}

.edit-device-name {
    font-size: 38pt;
    line-height: 10px;
}

.edit-device-name svg {
    cursor: pointer;
}

.edit-device-subtitle {
    font-size: 30pt;
    line-height: 10px;
}

.edit-device-spacer {
    width: 35%;
    height: 4px;
    background-color: var(--secondary-color);
}

.edit-device-secondary {
    padding: 1vh 100px;
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    align-items: center;
    gap: 30px;
    width: 40%;
}

/* 
.edit-device-text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px !important;
} */

.edit-device-text {
    font-family: "futura-pt", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 28px;
    margin: 0px !important;
}

.edit-device-list {
    display: flex;
    flex-direction: column;
    height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.edit-device-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.save-button {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 20px;
}

.app-list {
    display: flex;
    height: auto;
    overflow-x: hidden;
    flex-wrap: wrap;
    gap: 10px;
}

.app-list-item {
    width: 175px;
    height: 220px;
    border-radius: 15px;
    overflow: hidden;
    display: grid;
    grid-template-rows: 1fr 1.5fr;

    border: 1px solid #bcbec0;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    box-shadow: 1px 1px 5px #ffffff;
    scale: 1;
    transition: all ease 0.3s;
    background-color: white;
}

.app-list-background {
    width: 175px;
    background-size: cover;
}

.app-list-title {
    padding: 5px;
    font-size: 16pt;
    line-height: 20px;
    width: 175px;
    word-wrap: break-word;
}

.app-list-icon {
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 10px;
    padding: 5px;

    position: absolute;
    top: 70px;
}

.app-selected {
    border: 2px solid var(--tertiary-color);
    filter: brightness(0.95);
}