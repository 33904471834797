.login-form {
  width: 100%;
  display: flex;
  flex-direction: column;

  .title {
    margin-bottom: 20px;
  }

  .validation {
    margin-bottom: 20px;
    background-color: #fff1f0;
    border-radius: 10px;
    padding: 8px 16px;
    color: red;
    font-family: "futura-pt", sans-serif;
    font-size: 18px;
    transform: scaleY(0);
    transition: transform 500ms ease;

    &.show {
      transform: scaleY(1);
    }
  }

  .message {
    margin-bottom: 20px;
    background-color: #f4fff0;
    border-radius: 10px;
    padding: 8px 16px;
    color: green;
    font-family: "futura-pt", sans-serif;
    font-size: 18px;
    transform: scaleY(0);
    transition: transform 500ms ease;

    &.show {
      transform: scaleY(1);
    }
  }

  .forget-password {
    font-family: "futura-pt", sans-serif;
    font-size: 17px;
    align-self: flex-end;
  }

  .btn-container {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 24px;
  }

  .ant-form-item {
    & + .ant-form-item {
      margin-top: 6px;
    }
  }

  button {
    width: 100%;
    height: 45px;
  }

  .back-button-container {
    display: flex;
    align-items: center;
  }
  .back-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
    margin-right: 0px;
    width: 10%;
  }
}
