/* Styles to be used through entire app - RD */

* {
  /* font-family: "co-headline", sans-serif; */
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
  /* transition: all ease 0.3s; */
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: var(--text-color);
}

b {
  font-weight: 600;
}

h1,
h2,
h3 {
  font-family: "co-headline", sans-serif;
}

.container {
  width: 100%;
  padding: 80px 0 0 12vw;
}

tr {
  padding: 0;
}

:root {
  --primary-color: black;
  --secondary-color: #a51414;
  --tertiary-color: #24c6dc;
  --text-color: black;
  --alttext-color: white;
}

.primary {
  color: var(--primary-color);
}

.secondary-color {
  color: var(--secondary-color) !important;
}

.tertiary-color {
  color: var(--tertiary-color);
}

.text {
  color: var(--text-color);
  fill: var(--text-color);
}

.alttext {
  color: var(--alttext-color);
  fill: var(--alttext-color);
}

.full-height {
  height: 100vh;
}

.table-header-text {
  font-size: 14pt;
  white-space: nowrap;
  font-family: "futura-pt", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: var(--text-color);
}

.wifi {
  width: 50px;
}

a {
  text-decoration: none;
}

.full-width {
  width: 100%;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.headline {
  font-family: "co-headline", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 20px;
}

.mb20 {
  margin-bottom: 20px;
}

.Large {
  font-size: 50pt;
}

.medium {
  font-size: 30pt;
}

.small {
  font-size: 18pt;
}

.btn {
  padding: 15px 30px;
  border-radius: 50px;
  cursor: pointer;
  display: inline-block;
  border: none;
  font-size: 12pt;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.3s;
}

.btn:hover {
  scale: 1.05;
}

.main {
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  border-radius: 50px;
  color: var(--alttext-color);
}

.main:active {
  border-radius: 50px;
}

.main:focus {
  border-radius: 50px;
}

.secondary {
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid black;
  border-radius: 50px;
}

.secondary:active {
  border-radius: 50px;
}

.secondary:focus {
  border-radius: 50px;
}

.disallowed-text {
  filter: opacity(0.5) !important;
}

.pointer {
  cursor: pointer;
}

.offscreen {
  position: absolute;
  top: -5000px;
  color: inherit;
  transition: none;
}

.border-red {
  border: 1px solid red;
  outline: 1px solid red;
}

.border-red:focus,
.border-red:active {
  border: 1px solid red;
  outline: 1px solid red;
}

.body-text {
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12pt;
}

.boldText {
  font-weight: 700;
}

.forgotPasswordContainer {
  display: flex;
  flex-direction: column;
}

.tower-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* ---------------------------------- */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--primary-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.login-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.alpacaContent {
  padding: 0px !important;
  margin-left: 170px !important;
}

.icon-btn {
  font-size: 15pt;
  padding-top: 5px;
}
