html {
  --primary-color: "black";
  --secondary-color: "yellow";
  height: 100%;
}

body {
  min-height: 100%;
}

.settings-header {
  padding: 1vh 100px;
  background-color: #dee7f2;
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: -1;
}

.settings-welcome-headline {
  font-size: 42pt;
}

.setting-grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: start;
  gap: 20px;
  justify-content: space-between;
  margin-right: 100px;
}

.setting-grid h2 {
  padding: 30px 0px;
}

.settings-container {
  padding: 0px 0px 0px 100px;
  width: 60%;
  background-color: white;
  height: 100%;
}

.preview-container-outer {
  border-radius: 15px;
  background-color: #fafafa;
  border: 1px solid #bcbec0;
  padding: 10px 20px;
  margin-top: 100px;
  width: 25vw;
}

.sample {
  width: 50%;
  height: 300px;
  background-color: #dcddde;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#caret {
  font-size: 32pt;
  margin-right: 0.25em;
  cursor: pointer;
}

.toggle-settings-btn {
  background-color: transparent;
  border: none;
}

.head-btn-container {
  display: flex;
  flex-direction: row;
  padding: 20px 0px;
  justify-content: space-between;
}

.client-logo-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.client-config-container {
  display: flex;
  flex-direction: column;
}

.client-config-item-color input[type="color"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0px;
  cursor: pointer;
  padding: 0px 0px 0px 0px;
  width: 100%;
  height: 100px;
  min-height: 100px;
}

.color-picker-grid {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  flex-wrap: wrap;
}

.color-picker-item {
  text-align: center;
  align-items: center;
  background-color: #fafafa;
  border: 1px solid #bcbec0;
  box-shadow: 5px 5px 5px #e5e5e5;
  padding: 10px;
  border-radius: 15px;
  width: 110px;
  height: 150px;
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.color-picker-container {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #bcbec0;
}

/* .color-picker-section div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

.color-picker-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
}

.reset-btn,
.reset-btn:active,
.reset-btn:focus {
  background-color: rgb(200, 200, 200) !important;
  color: black !important;
  border: 2px solid rgb(100, 100, 100) !important;
  border-radius: 10px;
  padding: 10px;
  font-size: 12pt;
  margin-left: 40px;
  cursor: pointer;
}

/* *:focus {
  border-radius: 0;
  outline: none;
} */

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-color-swatch {
  border: 0;
  border-radius: 0;
}

::-moz-color-swatch,
::-moz-focus-inner {
  border: 0;
}

::-moz-focus-inner {
  padding: 0;
}

.client-config-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 10px;
  max-width: 300px;
}

.client-config-item button svg {
  fill: var(--alttext-color);
}

.client-config-item h3 {
  font-size: 18pt;
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.client-config-item-color {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  padding: 10px 0px;
  gap: 20px;
}

.client-config-item-color h3 {
  font-size: 18pt;
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.logo-icon-canvas {
  padding: 10px;
  background-color: grey;
  border-radius: 15px;
  width: 75px;
  max-height: 350px;
}

.logo-horizontal-canvas-white {
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  width: 150px;
  max-height: 350px;
  border: 1px gray solid;
}

.logo-horizontal-canvas-black {
  padding: 10px;
  background-color: var(--primary-color);
  border-radius: 15px;
  width: 150px;
  max-height: 350px;
  border: 1px gray solid;
}

.logo-horizontal-canvas-white:hover {
  background-color: #e8e8e8;
}

.logo-horizontal-canvas-black:hover {
  box-shadow: inset 0px 0px 0px 200px rgba(255, 255, 255, 0.3);
}

.logo-icon-canvas img,
.logo-horizontal-canvas-black img {
  width: 100%;
  height: 100%;
  margin: auto;
}

.logo-icon-canvas img,
.logo-horizontal-canvas-white img {
  width: 100%;
  height: 100%;
  margin: auto;
}

caption {
  font-size: 12pt;
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: left;
}

/* .settings-selector-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: baseline;
  width: 100%;
  border-bottom: 2px solid var(--secondary-color);
  padding: 50px 10px 0px 100px;
}

.setting-selector {
  width: 150px;
  border: none;
  background-color: var(--secondary-color);
  font-size: 14px;
  cursor: pointer;
  box-shadow: inset 0 0 1000px rgba(255, 255, 255, 1);
  padding: 10px;
  height: 50px;
  margin: 0px 10px;
  color: var(--text-color)
} */

.color-preview-container {
  width: 100%;
  background-color: white;
  border: 1px solid #bcbec0;
  padding: 30px 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.preview-btn {
  padding: 15px 30px;
  border-radius: 50px;
  display: inline-block;
  border: none;
  font-size: 12pt;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-main {
  background-color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  border-radius: 50px;
  color: var(--alttext-color);
}

.preview-btn:hover {
  filter: brightness(100%);
  filter: saturation(100%);
}

.preview-hover {
  background-color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  border-radius: 50px;
  color: var(--alttext-color);
  filter: brightness(135%);
  filter: saturation(110%);
}

.preview-hover:hover {
  filter: brightness(135%);
  filter: saturation(110%);
}

.preview-light {
  background-color: var(--secondary-color);
  box-shadow: inset 0 0 500px rgba(255, 255, 255, 1);
  color: var(--text-color);
  border: var(--secondary-color) 1px solid;
}

.pb__modal__header-example {
  margin-top: 10px;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  color: white;
  font-size: 18px;
  font-family: "co-headline", sans-serif;
  font-weight: 400;
  font-style: normal;
  background: var(--tertiary-color);
  /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    var(--tertiary-color),
    var(--secondary-color)
  );
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    var(--tertiary-color),
    var(--secondary-color)
  );
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.pb__modal__controls-example {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.pb__modal__close-example svg path {
  color: white;
  scale: 1.5;
}

.pb__modal__close-example svg {
  overflow: visible;
}

/* PROFILE SETTINGS */
.profile-settings-container-outer {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.profile-settings-grid {
  padding: 0px 20px 20px 100px;
  width: 750px;
}

.profile-settings-header-outer {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 20px 0;
  gap: 0px;
  background: var(--secondary-color);
  border-radius: 10px;
  margin-bottom: 20px;
  word-wrap: break-word;
  overflow: hidden;
  max-width: 100%;
}

.profile-settings-header {
  display: flex;
  flex-direction: column;
  background: var(--secondary-color);
  text-align: left;
  align-items: flex-start;
  gap: 20px;
  word-wrap: break-word;
  overflow: hidden;
  max-width: 100%;
}

.profile-settings-name {
  font-size: 24pt;
  color: white;
  word-wrap: break-word;
  max-width: 100%;
}

.profile-avatar {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50%;
  background-color: #1c2039;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  pointer-events: stroke;
  user-select: none;
  font-size: 45px;
}

.profile-badge {
  padding: 10px 15px;
  border-radius: 10%;
  box-shadow: inset 0 0 0 50px rgba(255, 255, 255, 0.8);
}

.profile-badge p {
  margin: 0;
}

.profile-settings-body {
  padding: 0px;
}

.user-settings-row p,
.user-settings-row span {
  font-family: "futura-pt", sans-serif;
  font-style: normal;
}

.user-settings-row span {
  color: #1374ea;
}

.user-settings-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px 0px;
}

.user-settings-row-wide {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px 0px;
}

.focus-component {
  width: 100%;
}

.user-input {
  width: 100%;
}

.icon {
  width: 25px;
}

.profile-btn-container {
  width: 100%;
  padding-top: 20px;
  gap: 20px;
  display: flex !important;
  flex-direction: row;
}

.profile-btn {
  width: 20%;
}

/* ENV SETTINGS */

.env-setting-grid {
  padding: 0px 20px 20px 100px;
  width: 100%;
}

.env-btn {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.plus-svg {
  font-size: 25px;
  fill: white;
}

.ant-switch {
  border-radius: 100px !important;
}

.notif-left-section {
  width: 70%;
  transition: width 0.3s ease;
  padding: 0px 24px 0px 100px;
}

.notif-right-section {
  width: 30%;
  background-color: #dcdcdc;
  transition: width 0.3s ease;
  overflow: hidden;
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.notif-left-collapsed {
  width: 100%;
}

.notif-right-collapsed {
  width: 0%;
}


.notif-collapse-button {
  margin-right: 24px;
}

.notif-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.capitalize-inputs * {
  text-transform: capitalize !important;
}

.notif-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.head-btn-subheader {
  display: flex;
  flex-direction: column;
  gap: 0;
}