.environment-grid__wrapper {
  width: 100%;

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
}

.dropdown-tableview-option__wrapper {
  display: flex;
  flex-direction: column;

  .option-thead {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ccc;
    // font-weight: bold;

    .option-cell {
      background-color: #fafafa;
      border-left: 1px solid #f0f0f0;
      border-right: 1px solid #f0f0f0;
    }
  }
  .option-rows {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    background-color: transparent;
    font-size: 20px;

    &.selected {
      background-color: #e6f7ff;
    }
    &:hover:not(.selected) {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .option-cell {
    flex: 1;
    padding: 8px;
  }
}
