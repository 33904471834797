.user-settings__permission-container {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;

  hr {
    width: 70%;
    opacity: 0.5;
  }

  .user-settings__permission-options {
    max-height: 50vh;
    overflow-y: auto;
  }

  .user-permissions-row {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 4px 8px;

    label {
      margin-right: auto;
    }
  }
}
