.register {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background: #0052d4; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #6fb1fc,
    #4364f7,
    #0052d4
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #6fb1fc,
    #4364f7,
    #0052d4
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  overflow: hidden;

  .header {
    box-sizing: content-box;
    height: 60px;
    padding: 20px;

    img {
      height: inherit;
    }
  }

  .content {
    background: none;
    padding: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;

    .card {
      box-sizing: border-box;
      background-color: white;
      border-radius: 20px;
      box-shadow: 2px 2px 5px #cecece;
      padding: 40px;
      max-width: 800px;
      min-width: 360px;
      margin: 0 20px;

      .grid {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 3fr 2fr;
        grid-template-rows: auto auto;
        grid-template-areas:
          "title image"
          "form  image";
        grid-gap: 36px 32px;

        .card-title {
          grid-area: title;
          font-size: 36pt;
          vertical-align: middle;
          margin: 0;
        }

        .form {
          grid-area: form;
        }

        .register-illustration {
          grid-area: image;
          max-width: 300px;
          width: 100%;
          align-self: center;
        }
      }
    }
  }
}
