.userTable {
  width: 100%;
  padding: 40px;
  position: relative;
}

.userTableCollapse {
  padding: 40px 240px 0 40px;
}

.users-header {
  padding: 1vh 100px;
  background-color: #dee7f2;
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: -1;
}

.users-welcome-headline {
  font-size: 42pt;
}

.add-user-btn-container {
  position: absolute;
  top: 40px;
  right: 20px;
}

.settings-table {
  table {
    border-spacing: 0px !important;
    width: 100%;
  }

  td {
    padding: 10px 0;
    margin: 10px;
    font-family: "futura-pt", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14pt;
  }

  th {
    padding: 0px;
    font-family: "co-headline", sans-serif;
    font-size: 18pt;
    text-align: left;
    background-color: white;
    border-left: 1px solid rgb(240, 240, 240);
    border-right: 1px solid rgb(240, 240, 240);
    cursor: pointer;
  }

  td p {
    font-family: "futura-pt", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14pt;
  }

  .userTd {
    padding: 20px;
  }

  tr {
    background-color: #ffffff;
  }

  tr:hover {
    background-color: #fafafa;
  }
}

.user-avatar-container {
  display: grid;
  grid-template-columns: 50px auto;
  gap: 10px;
  white-space: nowrap;
}

.user-select {
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: white;
  border: 1px solid grey;
  border-radius: 10px;
  width: 80%;
  cursor: pointer;
}

.user-select:focus {
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: white;
  border: 1px solid grey;
  border-radius: 10px;
  width: 80%;
  cursor: pointer;
}

/* .active {
	padding: 0 5px;
	border-radius: 0px;
	background-color: #8cc63f;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 50px;
} */

/* .inactive {
	padding: 0 5px;
	border-radius: 0px;
	background-color: #ed1c23;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 50px;
} */

/* .active p,
.inactive p {
	color: white;
} */

.header__action--filter {
  padding-top: 10px;
}

.invite-btn {
  margin: auto;
  margin-left: 20px;
}
