.invite-user-form {
  @mixin scrollbar {
    &::-webkit-scrollbar {
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .tag-field {
    @include scrollbar();
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 5px 0;
    padding: 6px;
    border: 1px dashed gainsboro;
    border-radius: 10px;
    height: 360px;
    overflow-x: auto;
  }

  .email-tag {
    background-color: #f7f7f7;
    padding: 6px 12px;
    font-size: 15px;
    color: #393939;
    border: none;
    transition: all 200ms ease;
  }

  .ant-form-item {
    margin-bottom: 20px;
  }
}
