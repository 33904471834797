.devices-page {
  padding: 0px 100px;
}

.devices-header {
  padding: 1vh 100px;
  background-color: #dee7f2;
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: -1;
}

.devices-welcome-headline {
  font-size: 42pt;
}

.devices-lock-window {
  background-color: white;

  height: 100%;
  display: flex;
  padding: 20px;
  gap: 20px;
  background-color: rgb(240, 240, 240) !important;
  border: 2px solid rgb(200, 200, 200) !important;
  border-radius: 10px;
  border: 2px solid #dee7f2;
}

.devices-lock-main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.devices-whitelist-main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.devices-whitelist-window {
  background-color: white;

  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  background-color: rgb(240, 240, 240) !important;
  border: 2px solid rgb(200, 200, 200) !important;
  border-radius: 10px;
  border: 2px solid #dee7f2;

  line-height: 10px;
}

.app-selector {
  height: 200px;
  width: 200px;
  background-color: white;
  border: 2px solid white;
  border-radius: 10px;

  text-align: center;
  padding: 20px;
  font-size: 24px;

  cursor: pointer;
}

.app-selector:focus {
  border: 2px solid var(--secondary-color);
}

.app-selector-logo {
  width: 90%;
  padding: 10px;
}

.selectable-text {
  color: black;
  cursor: pointer;
}

.selectable-text:focus {
  color: var(--secondary-color);
}

.selectable-text:active {
  color: var(--secondary-color);
}

.confirm-btn {
  margin-left: 80%;
  margin-top: 20px;
}

.devices-list-header {
  background-color: #dee7f2;
  display: grid;
  grid-template-columns: 0.9fr 0.15fr 0.15fr;
  width: 100%;
  gap: 20px;
  padding: 20px 0px 20px 20px;
  align-items: center;
  margin-top: -30px;
}

.device-settings-btn {
  cursor: pointer;
  width: 100%;
  padding: 6.428571428571429px 15px;
  font-size: 16px;

  &:hover {
    box-shadow: 1;
  }
}

.device-settings-button-full {
  background-color: var(--secondary-color);
  color: var(--alttext-color);
  width: 90%;
  border: 0px;
}

.device-item-btn-icon {
  width: 20px;
  fill: white;
  margin-right: 20px;
}

.device-search {
  width: 62% !important;
  margin-left: 80px;
}

.device-item {
  display: grid;
  grid-template-columns: 0.05fr 0.2fr 0.3fr 0.5fr 0.2fr 0.05fr;
  gap: 20px;
  background-color: white;
  width: 98%;
  margin-left: 1%;
  padding: 10px;
  align-items: center;
}

.device-item:hover {
  background-color: #fafafa;
}

.device-item-favorite {
  width: 30px;
  height: 30px;
  color: lightgray;
  border-radius: 0px;
}

.device-item-favorite:active,
.device-item-favorite:focus {
  color: yellow;
  fill: yellow;
}

.device-item-app-container-outer {
  display: grid;
  grid-template-rows: auto;
  width: 100%;
}

.device-item-app-container {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  height: 100%;
  width: auto;
  padding: 20px;
  border: 1px solid #fafafa;
  border-radius: 50px;
}

.device-item-app {
  width: 50px;
}

.device-item-more {
  width: 40px;
  cursor: pointer;
}

.device-item-badge {
  padding: 15px 30px;
  font-size: 14pt;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  white-space: nowrap;
  border: none;
  width: 50%;
}

.badge-active {
  background-color: #29abe2;
  color: white;
}

.badge-inactive {
  background-color: #ed1c24;
  color: white;
}

.badge-disabled {
  background-color: #adadad;
  color: black;
}

.container {
  text-align: center;
  background-color: #fff;
  border: none;
  padding: 20px;
}

/* Example styles for center content */
.center-content h1 {
  font-size: 24px;
  color: #333;
}
