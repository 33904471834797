$alpacaYellow: #fac819;

@mixin container {
  gap: 40px;
  width: 800px;
  padding: 40px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 2px 2px 5px #848484;
  z-index: 99;
}

.forgotPasswordBackground {
  //   background: #0052d4;
  //   background: -webkit-linear-gradient(
  //     to right,
  //     #6fb1fc,
  //     #4364f7,
  //     #0052d4
  //   );
  //   background: linear-gradient(
  //     to right,
  //     #6fb1fc,
  //     #4364f7,
  //     #0052d4
  //   );
  background-image: url("../../assets/Img/ForgotPassword/ForgotPasswordBg-01.svg");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  .afterReset {
    flex-direction: column;
    @include container();

    .afterReset_btnContainer {
      display: flex;
      flex-direction: row;
      gap: 20px;
      padding: 20px 0;
    }

    .afterReset_helpBtn {
      background-color: transparent;
      border: 2px solid black;
      width: 100%;
      border-radius: 50px;

      span {
        margin: 0;
        padding: 0;
      }
    }
  }

  .forgotPasswordBtn {
    background-color: $alpacaYellow;
    width: 100%;
    border-radius: 50px;

    span {
      margin: 0;
      padding: 0;
    }
  }

  .forgotPasswordContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    @include container();

    .forgotPasswordContainer_columnLeft {
      width: 60%;
    }
    .forgotPasswordContainer_columnRight {
      width: 40%;
    }

    .inputContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px 0;
    }

    .clickHere {
      color: blue;
      transition: all 0.2 ease;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.instructions {
  color: red;
  font-size: 12pt;
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding-bottom: 1vh;
}
