  .user-settings-container-outer {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .user-settings-container {
    width: 800px;
    border: 2px solid white;
    border-radius: 10px;
    overflow: none;
    background-color: white;
  }
  
  .user-settings-header {
    padding: 10px 0;
    background-color: var(--secondary-color);
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 10px;
    height: 100px;
    margin-bottom: 20px;
  }
  
  .user-settings-name {
    font-size: 26pt;
    color: white;
  }
  
  .badge {
    padding: 5px;
    border-radius: 10%;
    background-color: rgba(255, 255, 255, 0.75);
  }
  
  .badge p {
    margin: 0;
  }

  .warning-text-delete {
    color: red;
    margin-bottom: 60px;
    position: absolute;
    bottom: 0;
  }
  
//   .user-settings-body {
//     padding: 40px;
//   }
  
  .user-settings-body table {
    width: 100%;
  }
  
  .user-settings-info-row {
    display: flex;
  
    .user-settings-info-container {
      flex: 1;
    }
  
    .user-settings__permission-container {
      min-width: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
  
      hr {
        width: 70%;
        opacity: 0.5;
      }
  
      .user-settings__permission-options {
        max-height: 50vh;
        overflow-y: auto;
      }
  
      .user-permissions-row {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 4px 8px;
  
        label {
          margin-right: auto;
        }
      }
    }
  }
  
  .edit-user-settings-row {
    display: grid;
    grid-template-columns: 2fr 8fr;
    width: 100%;
    margin-bottom: 20px;
    input {
        width: 100%;
    }
    align-items: center;
}
  
  .icon {
    width: 25px;
  }
  
  .edit-user-btn-container {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
    
    position: absolute;
    bottom: 0;

}
  
  .edit-user-settings-row label {
    display: block;
    margin: "3rem";
    min-width: 30%;
    width: fit-content;
  }
  .edit-user-settings-row input {
    display: inline-block;
    // width: 50%;
    padding: 0.5rem;
    font-size: 1rem;
    margin-bottom: 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  