.moduleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  background-color: white;
  box-shadow: 2px 2px 5px #cecece;
  border-radius: 20px;
  padding: 40px;
  transition: 0.3s ease all;
  cursor: pointer;
}

.gray-out-button {
  background-color: #e2e2e2;
}

.gray-out-button:hover {
  background-color: #e2e2e2;
  scale: 1 !important;
  box-shadow: 0px 0px 0px #cecece !important;
}

.moduleButton:hover img {
  transform: scale(1.15);
}

.moduleImg {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.moduleWord {
  font-size: 3rem;
}

.moduleImg img {
  height: 100px;
}

.module-btn-headline {
  margin: 20px 0px 0px 0px;
}