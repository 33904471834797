.moduleButtonList {
  display: grid;
  position: relative;
  grid-template-columns: repeat(7, 1fr);
  gap: 20px;
  transition: 1s ease all;
  padding: 0 50px 50px 0;
}

.moduleButtonList {
  padding-left: 40px;
}

.moduleWord {
  font-size: 24pt;
  margin-bottom: 20px;
}

@media screen and (max-width: 2000px) {
  .moduleButtonList {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media screen and (max-width: 1800px) {
  .moduleButtonList {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (max-width: 1600px) {
  .moduleButtonList {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1400px) {
  .moduleButtonList {
    grid-template-columns: repeat(3, 1fr);
  }
}
