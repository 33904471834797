.tooltip__content {
  margin-top: 10px;
  position: fixed;
  background: rgb(0, 0, 0);
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  z-index: 1;
  opacity: 0.9;
}

.tooltip__content--hidden {
  display: none;
}
