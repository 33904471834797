.userAvatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #1c2039;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: stroke;
  user-select: none;
}
