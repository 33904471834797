.register-form {
  $alpaca-color: #fed208;

  .ant-form-item {
    margin: 0;
    padding-bottom: 24px;
  }

  // reverse form error message
  .ant-form-item {
    .ant-form-item-control {
      display: flex;
      flex-direction: column-reverse;
    }

    .ant-form-item-explain-error {
      font-size: 17px;
      font-family: "futura-pt", sans-serif;
      font-weight: 300;
      line-height: 18px;
      margin-bottom: 16px;
      margin-left: 12px;
    }
  }

  .register-button {
    &,
    &:active {
      width: 100%;
      height: 45px;
      background-color: $alpaca-color;
      border-color: $alpaca-color;
    }

    &:hover {
      border: none;
    }
  }

  .already-have-account-container {
    p,
    span {
      font-family: "futura-pt", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 14pt;
    }

    .log-in-text {
      margin-left: 8px;
    }
  }

  .ant-input {
    &:not([value=""]).ant-input-status-success {
      border: 1px solid #52c41a;
    }
  }

  .ant-input-password {
    &.ant-input-affix-wrapper-status-success {
      border: 1px solid #52c41a;
    }
  }

  .ant-select {
    &.ant-select-status-success .ant-select-selector {
      border: 1px solid #52c41a;
    }
  }
}
