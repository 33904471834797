.invite-switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  .invite-switch-text {
    background-color: var(--secondary-color);
    box-shadow: inset 0 0 0 50px rgba(255, 255, 255, 0.8);
    transition: all ease 0.3s;
    padding: 5px 20px;
    border-radius: 15px;
  }

  .invite-switch-text-selected {
    background-color: var(--secondary-color);
    box-shadow: inset 0 0 0 50px rgba(255, 255, 255, 0.5);
  }
}
