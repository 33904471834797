
  .message {
    text-align: center;
    padding: 20px;
  }
  
  .message h2 {
    color: #ff6347; /* Red color for emphasis */
  }
  
  .message p {
    font-size: 18px;
    margin: 10px 0;
  }