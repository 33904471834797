.settings-selector-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid var(--secondary-color);
  box-shadow: inset 0px -30px 25px -10px rgb(240, 240, 240);
  padding: 15px 10px 0px 100px;
}

.setting-selector {
  width: 150px;
  border: 1px solid var(--secondary-color);
  border-bottom: none;
  background-color: var(--secondary-color);
  font-size: 18px;
  cursor: pointer;
  box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.85);
  filter: saturate(1.5);
  padding: 10px;
  height: 50px;
  margin: 0px 10px;
  color: var(--text-color);
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.setting-selector-active {
  width: 150px;
  border: 1px solid var(--secondary-color);
  border-bottom: none;
  font-size: 18px;
  cursor: pointer;
  padding: 10px;
  height: 50px;
  margin: 0px 10px;
  background-color: var(--secondary-color);
  box-shadow: inset 0 0 0px rgba(255, 255, 255, 0.5);
  color: var(--alttext-color);
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.setting-selector:hover {
  box-shadow: inset 0 0 500px rgba(255, 255, 255, 0.6);
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 500px;
  height: 300px;
  background: #f1f1f1;
  margin: 100px auto 0;
  word-break: break-all;
  border: 1px solid rgba(0, 0, 0, 0.274);
}

.content {
  background: white;
  padding: 20px;
  padding-top: 5px;
  width: 100%;
  height: 100%;
  display: none;
}

.active-content {
  display: block;
}