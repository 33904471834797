.env-user-settings-container-outer {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.env-user-settings-container {
  width: 900px;
  border: 2px solid white;
  border-radius: 10px;
  overflow: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90vh; /* adjust as needed */
}

.env-user-settings-header {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 10px;
}

.env-user-settings-name {
  font-size: 26pt;
  color: rgb(0, 0, 0);
}

.env-badge {
  padding: 5px;
  border-radius: 10%;
  background-color: rgba(255, 255, 255, 0.75);
}

.env-badge p {
  margin: 0;
}

.env-user-settings-body {
  padding: 0px;
  flex-grow: 1;
  overflow: auto;
}

.optional-modal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}



.env-user-settings-row p,
.env-user-settings-row span {
  font-family: "futura-pt", sans-serif;
  font-style: normal;
  font-size: 18pt;
}

.env-user-settings-row span {
  /* font-weight: 700; */
  color: #1374ea;
  cursor: pointer;
}

.env-user-settings-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.env-icon {
  width: 25px;
}

.env-accordion {
  width: 100%;
}


.env-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 5%;
}
.env-user-settings-info-container {
  display: grid;
  row-gap: 50px;
  height: 100%;
  width: 100%;
}

/* .env-grid {
    margin-top: 50px;
}

.env-grid th {
    padding: 10px;
}

.env-type {
    padding: 10px;
    width: 50%;
    text-align: center;
    border-radius: 10%;
    background-color: rgba(219, 219, 219, 0.75);
} */

.env-dropdown {
  margin-top: -50px;
  margin-bottom: 20px;
}
