.modules-header {
  padding: 1vh 100px;
  background-color: #dee7f2;
  margin-bottom: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: -1;
}

.installed-apps-btn-container {
  position: absolute;
  right: 50px;
  bottom: -50px;
}

.modules-header-img {
  width: 110px;
}

.modules-welcome-headline {
  font-size: 42pt;
}

.installed-apps-btn {
  background-color: transparent;
  border: none;
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16pt;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  padding: 10px;
}

.installed-apps-btn:hover {
  background-color: #dee7f2;
}
