.login {
  height: 100vh;
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: auto;
  gap: 75px;
  padding: 0 50px;

  .alpaca-logo {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 200px;
  }

  section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    &.graphic {
      img {
        text-align: left;
        width: 100%;
        max-width: 48vw;
      }
    }

    &.form {
      max-width: 500px;

      .title {
        font-family: "co-headline", sans-serif;
        font-size: 50px;
      }
    }
  }
}

.company-selector-outer {
  min-width: 500px;
}

.company-mark {
  display: grid;
  grid-template-columns: 0.5fr 3fr 0.5fr;
  align-items: center;
  gap: 10px;
  height: 50px;
  margin: auto;
}

.company-selector {
  width: 40px;
  margin: auto;
  mix-blend-mode: multiply;
  // this multiply is here because images are not transparent. probably a good idea to get rid of this whenever we get new images
}

.company-text {
  padding: 0;
  text-align: left;
  font-size: 16pt;
}

.company-selector-outer .ant-select-selector {
  height: 50px !important;
  // border: none !important;
}
