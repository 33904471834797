.create-env-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
}

.create-env-input-btn {
    display: grid;
    grid-template-columns: 0.4fr 2fr;
    align-items: center;
    width: 100%;
    padding: 5px;
    gap: 10px;
}

.create-env-input-btn-special {
    display: grid;
    grid-template-columns: 0.4fr 1.8fr 0.2fr;
    align-items: center;
    width: 100%;
    padding: 5px;
    gap: 10px;
}

.create-env-input-btn-type {
    display: grid;
    grid-template-columns: 0.4fr 1.4fr 0.6fr;
    align-items: center;
    width: 100%;
    padding: 5px;
    gap: 10px;
}

.subtext {
    color: gray;
    line-height: 0px;
    padding-bottom: 10px;
}

.help-circle {
    font-size: 25px;
    margin: auto;
}

.white-tooltip {
    color: white;
}

.link-text {
    color: #1677ff;
}