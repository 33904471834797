.invite-user-modal {
  display: grid;
  grid-template-columns: 60% 40%;

  .ant-form-item-explain-error {
    margin-left: 5px;
    margin-bottom: 10px;
  }

  .form {
    margin: 10px 5px;
  }
}
